import { DataType, EventsType, StoryField, StoryInteractionField } from './types';

export const storyFieldDefault: StoryField = {
  event: 'story_view',
  story_title: '',
  story_category: '',
};

export const storyInteractionField: StoryInteractionField = {
  event: 'clickEvent',
  storyTitle: '', // The title of the story
  clickText: '', // The text of the button or link in the original language
  clickURL: '', // URL of the destination referred to by the button or link.
  }

export const pushDataLayer = (event: EventsType, attrs: DataType): void => {
  const pushHandler = {
    storyView: {
      ...attrs.story,
    },
    clickEvent: {
      ...attrs.storyInteractions,
    },
  };

  window.dataLayer.push(pushHandler[event]);
};
