
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import {
  pushDataLayer,
  storyInteractionField,
} from '@/utils/GTMCustomDataLayers/stories';
import { StoryInteractionField } from '@/utils/GTMCustomDataLayers/types';

import Button from '@/components/Button/Button.vue';

export default defineComponent({
  components: {
    Button,
  },
  props: {
    articlePageId: {
      type: Number,
      default: null,
    },
    articlePageSlug: {
      type: String,
      default: null,
    },
    articleHeader: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
    },
    form: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const isRead = computed(() =>
      store.state.storiesModule.provisionalReadStoriesIds.includes(
        props.articlePageId
      )
    );

    const onStoryRead = () => {
      const formItems = props.form
        ? Object.entries(props.form.getValues())
        : null;
      let count = 0;

      if (formItems)
        formItems?.map((item) => {
          // eslint-disable-next-line vue/no-mutating-props
          if (
            item &&
            !store.state.storiesModule.selectedStoryDropdownOption[item[0]]
          ) {
            count++;
            store.dispatch('storiesModule/setStoryDropdownError', {
              key: item[0],
              error: 'error',
            });
          }
        });

      if (count) return;

      prepareAndSendGTMObject(
        props.articleHeader,
        isRead.value ? props.data?.cta_success : props.data?.cta_title,
        `https://de.iqosclub.com/stories/${props.articlePageSlug}`
      );

      const dropdownItems = Object.entries(
        store.state.storiesModule.selectedStoryDropdownOption
      );

      if (dropdownItems.length)
        dropdownItems.map((item, index) => {
          setTimeout(() => {
            store.dispatch('storiesModule/markStoryRead', {
              pageId: props.articlePageId,
              payload: {
                slug: props.articlePageSlug,
                log_message: item[1],
              },
            });
          }, 1500 * index);
        });
      else {
        store.dispatch('storiesModule/markStoryRead', {
          pageId: props.articlePageId,
          payload: {
            slug: props.articlePageSlug,
            log_message: null,
          },
        });
      }
    };

    const prepareAndSendGTMObject = (title, text, url) => {
      const storyGTMObject: StoryInteractionField = {
        ...storyInteractionField,
        storyTitle: title,
        clickText: text,
        clickURL: url,
      };
      pushDataLayer('clickEvent', { storyInteractions: storyGTMObject });
    };

    return {
      isRead,
      onStoryRead,
    };
  },
});
