
import { defineComponent } from 'vue';

import Button from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';

import EndTeaser from '@/modules/stories/components/EndTeaser/EndTeaser.vue';
import StoriesLogo from '@/modules/stories/components/StoriesLogo/StoriesLogo.vue';

export default defineComponent({
  components: {
    Button,
    Icon,
    EndTeaser,
    StoriesLogo,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
  },
  setup() {
    const teasers = [
      {
        id: '1',
        index: 0,
        subtitle:
          'Erfahre und erschaffe überraschende musikalische Momente gemeinsam mit der IQOS Community.',
        title: 'IQOS TogetherXMusic',
        url: '/togetherx',
        image: {
          alt: 'Erfahre und erschaffe überraschende musikalische Momente gemeinsam mit der IQOS Community.',
          desktop: {
            x1: require('@/modules/stories/assets/fallback/fallback-teaser-togetherx@1x.png'),
            x2: require('@/modules/stories/assets/fallback/fallback-teaser-togetherx@2x.png'),
            x3: require('@/modules/stories/assets/fallback/fallback-teaser-togetherx@3x.png'),
          },
          mobile: {
            x1: require('@/modules/stories/assets/fallback/fallback-teaser-togetherx@1x.png'),
            x2: require('@/modules/stories/assets/fallback/fallback-teaser-togetherx@2x.png'),
            x3: require('@/modules/stories/assets/fallback/fallback-teaser-togetherx@3x.png'),
          },
        },
      },
      {
        id: '2',
        index: 1,
        subtitle:
          'Empfehle IQOS ILUMA Deinen Freund*innen und ihr profitiert gemeinsam.',
        title: 'IQOS empfehlen',
        url: 'https://www.iqos.com/de/de/freunde-empfehlen.html',
        image: {
          alt: 'IQOS empfehlen',
          desktop: {
            x1: require('@/modules/stories/assets/fallback/fallback-teaser-empfehlen@1x.png'),
            x2: require('@/modules/stories/assets/fallback/fallback-teaser-empfehlen@2x.png'),
            x3: require('@/modules/stories/assets/fallback/fallback-teaser-empfehlen@3x.png'),
          },
          mobile: {
            x1: require('@/modules/stories/assets/fallback/fallback-teaser-empfehlen@1x.png'),
            x2: require('@/modules/stories/assets/fallback/fallback-teaser-empfehlen@2x.png'),
            x3: require('@/modules/stories/assets/fallback/fallback-teaser-empfehlen@3x.png'),
          },
        },
      },
      {
        id: '3',
        index: 2,
        subtitle:
          'Spielerisch mehr über IQOS lernen und dabei fleißig Punkte sammeln.',
        title: 'IQOS CLUB Academy',
        url: '/academy',
        image: {
          alt: 'IQOS CLUB Academy',
          desktop: {
            x1: require('@/modules/stories/assets/fallback/fallback-teaser-academy@1x.png'),
            x2: require('@/modules/stories/assets/fallback/fallback-teaser-academy@2x.png'),
            x3: require('@/modules/stories/assets/fallback/fallback-teaser-academy@3x.png'),
          },
          mobile: {
            x1: require('@/modules/stories/assets/fallback/fallback-teaser-academy@1x.png'),
            x2: require('@/modules/stories/assets/fallback/fallback-teaser-academy@2x.png'),
            x3: require('@/modules/stories/assets/fallback/fallback-teaser-academy@3x.png'),
          },
        },
      },
    ];

    return {
      teasers,
    };
  },
});
