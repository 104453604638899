
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import useForm, { FormFieldDict } from '@/use/useForm';

import Icon from '@/components/Icon/Icon.vue';
import Button from '@/components/Button/Button.vue';
import LinkButton from '@/components/LinkButton/LinkButton.vue';

import { Footnote, PageContent, PageCta, Story } from '../../types';
import { routeNames } from '../../router';

import {
  pushDataLayer,
  storyInteractionField,
} from '@/utils/GTMCustomDataLayers/stories';
import { StoryInteractionField } from '@/utils/GTMCustomDataLayers/types';

import StoryAuthor from '../StoryModules/StoryAuthor.vue';
import StoryCite from '../StoryModules/StoryCite.vue';
import StoryCopy from '../StoryModules/StoryCopy.vue';
import StoryIntroblock from '../StoryModules/StoryIntroblock.vue';
import StoryImage from '../StoryModules/StoryImage.vue';
import StoryMedia from '../StoryModules/StoryMedia.vue';
import StoryPrimary from '../StoryModules/StoryPrimary.vue';
import StorySecondary from '../StoryModules/StorySecondary.vue';
import StoryDropdown from '../StoryModules/StoryDropdown.vue';
import ArchiveCarousel from '../ArchiveCarousel/ArchiveCarousel.vue';

export default defineComponent({
  components: {
    ArchiveCarousel,
    StoryAuthor,
    StoryCite,
    StoryCopy,
    StoryIntroblock,
    StoryImage,
    StoryMedia,
    StoryPrimary,
    StorySecondary,
    StoryDropdown,
    Button,
    Icon,
    LinkButton,
  },
  props: {
    articlePageId: {
      type: Number,
      default: null,
    },
    articlePageSlug: {
      type: String,
      default: null,
    },
    articleHeader: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    storyContent: {
      type: Array as PropType<PageContent>,
      default: null,
    },
    storyCTAs: {
      type: Array as PropType<PageCta>,
      default: null,
    },
    footnotes: {
      type: Array as PropType<Footnote[]>,
      default: () => [],
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['goBack'],
  setup(props, { emit }) {
    const store = useStore();
    const { push } = useRouter();

    const dropdownItems = props.storyContent.filter(
      (item) => item.type === 'dropdown'
    );

    const storyFormConfig: FormFieldDict = dropdownItems.length
      ? reactive(
          props.storyContent.reduce((acc, item, index) => {
            if (item?.type === 'dropdown')
              acc[`dropdown${index}`] = {
                currentValue: '',
                validators: [() => (value) => !value],
              };
            return acc;
          }, {})
        )
      : null;

    const storyForm = storyFormConfig ? useForm(storyFormConfig) : null;

    const prepareAndSendGTMObject = (title, text, url) => {
      const storyGTMObject: StoryInteractionField = {
        ...storyInteractionField,
        storyTitle: title,
        clickText: text,
        clickURL: url,
      };
      pushDataLayer('clickEvent', { storyInteractions: storyGTMObject });
    };

    const handleBack = () => {
      prepareAndSendGTMObject(
        props.articleHeader,
        'Zurück zur Übersicht',
        'https://de.iqosclub.com/stories'
      );
      emit('goBack');
    };

    const getComponentName = (type: string) => {
      const componentName = type.split('_')[0];
      return `Story${startCase(toLower(componentName))}`;
    };

    const stories: ComputedRef<Story[]> = computed(
      () => store.getters['storiesModule/readStories']
    );

    const teasers = computed(() =>
      stories.value.filter((story) => story.category === props.category)
    );

    const handleTeaserClick = (story: Story) => {
      store.dispatch('storiesModule/selectArchivedStory', story);
      push(routeNames.STORIES_HOME);
    };

    const isArchivedComputed  = computed(() =>
      {
        if (props.isArchived === null && props.articlePageSlug) { // direct link via slug has no isArchived prop
          return stories.value.find((story) => story.meta?.slug === props.articlePageSlug);
        }
        return props.isArchived
      }
    );

    return {
      storyForm,
      teasers,
      handleTeaserClick,
      getComponentName,
      prepareAndSendGTMObject,
      handleBack,
      archiveUrl: routeNames.STORIES_ARCHIVE,
      storiesHomeUrl: routeNames.STORIES_HOME,
      isArchivedComputed,
    };
  },
});
