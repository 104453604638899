
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<{ header: string; content: string }>,
      required: true,
    },
  },
});
