
import { defineComponent } from 'vue';

import Icon from '@/components/Icon/Icon.vue';

const EVENT_CLOSE = 'onClose';

export default defineComponent({
  components: {
    Icon,
  },
  emits: [EVENT_CLOSE],
  setup(_, { emit }) {
    const onCloseModal = () => emit(EVENT_CLOSE);
    return {
      onCloseModal,
    };
  },
});
