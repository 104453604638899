
import { defineComponent, ComputedRef, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Stories } from '../../types';
import { routeNames } from '../../router';

import StoriesHome from './StoriesHome.vue';

export default defineComponent({
  components: {
    StoriesHome,
  },
  setup() {
    const { push } = useRouter();
    const store = useStore();
    store.dispatch('storiesModule/getStories');

    const stories: ComputedRef<Stories> = computed(
      () => store.getters['storiesModule/currentStories']
    );

    watch(stories, (value) => {
      if (!value?.items?.length) push(routeNames.STORIES_FALLBACK);
    });

    return {
      stories,
    };
  },
});
