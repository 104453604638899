import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4d5ce34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "story-image bg-primary-soft-white" }
const _hoisted_2 = { class: "story-image-card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pt-2 px-8 pb-10 xl:pb-12" }
const _hoisted_5 = { class: "story-image-card__headline text-fs-14-lh-20-ls-0.2 font-bold mb-1 xl:mb-2.5 text-primary-slate" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.data?.images?.mobile_s?.download_url)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(["story-image-card__image object-cover w-full", { 'px-8': _ctx.data?.image_mode === _ctx.CMS_IMAGE_MODES.normal }]),
            src: 
          _ctx.data?.images.mobile_s.download_url ||
          _ctx.data?.images.mobile_m.download_url ||
          _ctx.data?.images.mobile_m.download_url ||
          _ctx.data?.images.desktop_xl.download_url ||
          _ctx.data?.images.desktop_xl.download_url ||
          _ctx.data?.images.desktop_xxl.download_url
        ,
            alt: "story-image"
          }, null, 10, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.data?.header), 1),
        _createElementVNode("div", {
          class: "story-image-card__text text-fs-14-lh-20-ls-0.2 font-normal text-primary-slate",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prepareAndSendGTMObject(_ctx.articleHeader, _ctx.data?.content))),
          innerHTML: _ctx.data?.content
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}