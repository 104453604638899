
import { defineComponent } from 'vue';
import Icon from '@/components/Icon/Icon.vue';
import { ResponsiveImageSet } from '@/types';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

export default defineComponent({
  components: {
    ResponsiveImage,
    Icon,
  },
  props: {
    image: {
      type: Object as () => ResponsiveImageSet,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
});
