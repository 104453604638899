
import { defineComponent, PropType } from 'vue';
import { Images } from '../../types';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<{
        author: string;
        story: string;
        images: Images;
      }>,
      required: true,
    },
  },
});
