import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe95be32"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "end-teaser" }
const _hoisted_3 = { class: "relative z-10 pl-s pr-m pb-4 text-primary-soft-white" }
const _hoisted_4 = { class: "text-xl leading-7 mb-2" }
const _hoisted_5 = { class: "text-xs pr-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.url || '#'
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ResponsiveImage, {
        class: "absolute w-full h-full z-0",
        lightDarkOverlay: "",
        responsiveImageSet: _ctx.image
      }, null, 8, ["responsiveImageSet"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.text), 1)
      ]),
      _createVNode(_component_Icon, {
        type: "back",
        theme: "light",
        class: "h-6 absolute bottom-s right-m transform rotate-180"
      })
    ])
  ], 8, _hoisted_1))
}