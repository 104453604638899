
import { VueperSlide } from 'vueperslides';
import {
  defineComponent,
  computed,
  ComputedRef,
  PropType,
  ref,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useCarousel from '@/use/useCarousel';
import useBreakpoints from '@/use/useBreakpoints';

import { Stories } from '@/modules/stories/types';
import TeaserCard from '@/modules/stories/components/TeaserCard/TeaserCard.vue';

import Icon from '@/components/Icon/Icon.vue';
import Carousel from '@/components/Carousel/Carousel.vue';
import StoryBarAutoPlay from '@/components/StoryBarAutoPlay/StoryBarAutoPlay.vue';

import 'vueperslides/dist/vueperslides.css';
import { pushDataLayer } from '@/utils/GTMCustomDataLayers/stories';

import { routeNames } from '@/modules/stories/router';

const visibleSlidesPerBreakpoint = {
  xl: 1,
  lg: 1,
  md: 1,
  sm: 1,
};

export default defineComponent({
  components: {
    VueperSlide,
    Carousel,
    TeaserCard,
    StoryBarAutoPlay,
    Icon,
  },
  props: {
    stories: {
      type: Object as PropType<Stories>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter()

    const storyBarAutoPlayRef = ref();
    const carouselRef = ref();
    const sliderDisabled = ref(false);
    const preventYScrollTeaser = ref(false);
    const isOpenTeaserModal = ref(false);

    const innerSlides = ref(props.stories?.items || [])

    const breakpointsService = useBreakpoints();
    const { breakpoints } = breakpointsService;
    const isDesktop = breakpointsService.isLg();
    const isMobile = !breakpointsService.isMd();

    const totalItems: ComputedRef<number> = computed(
      () => props.stories?.items?.length
    );

    const provisionalReadStoriesIds = computed(() => store.state.storiesModule.provisionalReadStoriesIds)
    const isLastStoryRead = computed(() =>
      innerSlides.value.length === 1 &&
      provisionalReadStoriesIds.value.includes(innerSlides.value[0]?.id)
    );


    const carouselBreakpoints = {
      9999: {
        // larger than lg
        visibleSlides: visibleSlidesPerBreakpoint.xl,
        touchable: false,
        initSlide: 1,
      },
      [breakpoints.xl - 1]: {
        // lg
        visibleSlides: visibleSlidesPerBreakpoint.lg,
        touchable: false,
        initSlide: 1,
      },
      [breakpoints.lg - 1]: {
        // md
        visibleSlides: visibleSlidesPerBreakpoint.md,
        touchable: false,
        initSlide: 1,
      },
      [breakpoints.md - 1]: {
        // sm and lower
        visibleSlides: visibleSlidesPerBreakpoint.sm,
        touchable: isMobile,
        initSlide: 1,
      },
    };

    const handleStoryBarPosition = () => {
      if (!isDesktop) {
        return;
      }
      const teaserInfoItems = document.querySelectorAll(
        '.teaser-carousel .vueperslide .teaser-card__info'
      );
      let top = 0;
      for (let i = 0; i < teaserInfoItems.length; i++) {
        const item = teaserInfoItems[i];
        if (item instanceof HTMLElement && top < item.offsetTop) {
          top = item.offsetTop;
        }
      }

      storyBarAutoPlayRef.value.storyBarTop = top + 24;
    };

    window.addEventListener('resize', () => {
      handleStoryBarPosition();
    });

    onMounted(() => {
      handleStoryBarPosition();
      if (props.stories?.items.length) {
        const storyItem = props.stories?.items[0];
        prepareAndSendGTMObject(storyItem);
      }
    });

    const { carouselBreakpointsConfig } = useCarousel({
      visibleSlidesPerBreakpoint,
      totalItems,
      carouselBreakpoints,
    });

    const preventNavigationOnSlideDrag = (event) => {
      if (carouselRef.value.$refs.vueperRef.justDragged()) {
        event.preventDefault();
      }
    };

    const prepareAndSendGTMObject = (storyItem) => {
      const { category, title } = storyItem || {};

      const storyGTMObject = {
        event: 'story_view',
        story_title: title,
        story_category: category,
      };

      pushDataLayer('storyView', { story: storyGTMObject });
    };

    const handleReadArticleSlideRemoval = () => {
      if(store.state.storiesModule.provisionalReadStoriesIds.length) {
        const elementToRemove = innerSlides.value.find(item => item.id === store.state.storiesModule.provisionalReadStoriesIds[0] )
        const indexToRemove = innerSlides.value.indexOf(elementToRemove)
        innerSlides.value.splice(indexToRemove, 1)
        setTimeout(() => {
          carouselRef.value.$refs.vueperRef.goToSlide(indexToRemove, { emit: false, animation: false });
          storyBarAutoPlayRef.value.onChangeBar({ currentSlide: { index: indexToRemove }});
        }, 10)
        store.dispatch('storiesModule/unsetProvisionalReadStoriesIds');
      }
    }

    const onNavigateStoryBar = (slide) => {
      if (slide?.currentSlide) {
        const storyItem = props.stories?.items[slide?.currentSlide?.index];
        prepareAndSendGTMObject(storyItem);
      }
      storyBarAutoPlayRef.value?.onChangeBar(slide);

      if(isLastStoryRead.value) {
        router.push(routeNames.STORIES_FALLBACK)
      } else {
        handleReadArticleSlideRemoval();
      }
    };

    const onInteractStoryBar = (event, value: boolean) => {
      if (!event) {
        preventYScrollTeaser.value = value;
        isOpenTeaserModal.value = value;
      }

      if (
        event &&
        (event.target.tagName === 'IMG' || isOpenTeaserModal.value)
      ) {
        return;
      }

      if (
        event &&
        event.target.tagName === 'BUTTON' &&
        event.target.classList.contains('teaser-card__info__button') &&
        !value
      )
        return;

      if (event && event.target.tagName !== 'BUTTON') {
        if (
          sliderDisabled.value &&
          document.querySelector(
            '.vueperslide--visible  > .teaser-card > .teaser-card__container > .stories-content-modal '
          ) !== null
        )
          return;
        preventNavigationOnSlideDrag(event);
      }

      storyBarAutoPlayRef.value.timeline[value ? 'pause' : 'play']();
      sliderDisabled.value = value;
    };

    const onChangeSlide = (slide: number) => {
      const storyItem =
        innerSlides.value[slide + 1 < innerSlides.value.length ? slide + 1 : 0];

      prepareAndSendGTMObject(storyItem);

      carouselRef.value.$refs.vueperRef.goToSlide(slide + 1, { emit: false });

      if(isLastStoryRead.value) {
        router.push(routeNames.STORIES_FALLBACK)
      } else {
        handleReadArticleSlideRemoval();
      }
    };

    return {
      carouselBreakpointsConfig,
      carouselRef,
      innerSlides,
      isDesktop,
      isMobile,
      onChangeSlide,
      onInteractStoryBar,
      onNavigateStoryBar,
      preventYScrollTeaser,
      provisionalReadStoriesIds,
      sliderDisabled,
      storyBarAutoPlayRef,
      totalItems,
      isLastStoryRead,
    };
  },
});
