
import { defineComponent } from 'vue';
import {
  pushDataLayer,
  storyInteractionField,
} from '@/utils/GTMCustomDataLayers/stories';
import { StoryInteractionField } from '@/utils/GTMCustomDataLayers/types';

const CMS_IMAGE_MODES = { normal: 'normal', full: 'full' };

export default defineComponent({
  props: {
    data: {
      type: Object,
    },
    articleHeader: {
      type: String,
      default: null,
    },
  },
  setup() {
    const prepareAndSendGTMObject = (title, contentText) => {
      const patternText = contentText.match(/<a.*">(.*)<\/a>/i);
      const patternUrl = contentText.match(/<a href="(.*)">/i);
      const text = patternText ? patternText[1] : '';
      const url = patternUrl ? patternUrl[1] : '';

      const storyGTMObject: StoryInteractionField = {
        ...storyInteractionField,
        storyTitle: title,
        clickText: text,
        clickURL: url,
      };
      pushDataLayer('clickEvent', { storyInteractions: storyGTMObject });
    };
    return { CMS_IMAGE_MODES, prepareAndSendGTMObject };
  },
});
