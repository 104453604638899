import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07533d76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "story-dropdown bg-primary-soft-white py-5 px-8" }
const _hoisted_2 = { class: "story-dropdown__subheadline text-fs-32-lh-35-ls-0.46 break-words font-bold uppercase text-primary-slate" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "story-dropdown__content pb-5 relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.data.header), 1),
    _createElementVNode("div", {
      class: "text-fs-14-lh-24-ls-0 font-normal text-primary-slate pb-5",
      innerHTML: _ctx.data?.content
    }, null, 8, _hoisted_3),
    (_ctx.data.choices.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Select, {
            options: _ctx.data.choices,
            placeholder: "Hier auswählen",
            error: _ctx.dropdownError && _ctx.dropdownError[_ctx.keyName],
            onInput: _cache[0] || (_cache[0] = (option) => _ctx.onSelectOption(option))
          }, null, 8, ["options", "error"])
        ]))
      : _createCommentVNode("", true)
  ]))
}