
import {
  defineComponent,
  computed,
  ComputedRef,
  onMounted,
  PropType,
  ref,
} from 'vue';
import anime from 'animejs/lib/anime.es';

const CHANGE_STORY_BAR = 'changeStoryBar';

export default defineComponent({
  props: {
    items: {
      type: Array,
    },
    timelineProps: {
      type: Object as PropType<{
        autoplay: boolean;
        loop: boolean;
        duration: number;
      }>,
    },
    className: {
      type: Object,
    },
  },
  emits: [CHANGE_STORY_BAR],
  setup(props, { emit }) {
    let indirectChange = false;
    let storyBarTop = ref(10);

    const totalItems: ComputedRef<number> = computed(() => {
      return props.items.length;
    });

    const gridStyle = computed(() => {
      return {
        gridTemplateColumns: `repeat(${totalItems.value}, 1fr)`,
      };
    });

    const timeline = computed(() =>
      anime.timeline({
        duration: 5000,
        easing: 'linear',
        ...props?.timelineProps,
      })
    );

    const onChangeBar = (slide) => {
      indirectChange = true;
      timeline.value.pause();
      timeline.value.seek(
        slide.currentSlide.index * (props?.timelineProps?.duration || 5000)
      );
      timeline.value.play();
      indirectChange = false;
    };

    onMounted(() => {
      props.items.forEach((_, index) => {
        timeline.value.add({
          targets:
            document.querySelectorAll('.story-bar__item')[index].children[0],
          width: '100%',
          changeComplete: () => {
            if (!indirectChange) emit(CHANGE_STORY_BAR, index);
          },
        });
      });
    });

    return {
      totalItems,
      timeline,
      gridStyle,
      storyBarTop,
      onChangeBar,
    };
  },
});
