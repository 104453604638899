import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "story-copyblock bg-primary-soft-white mb-4 mt-8" }
const _hoisted_2 = { class: "story-copyblock-card py-5 px-8" }
const _hoisted_3 = { class: "story-copyblock-card__headline text-fs-32-lh-35-ls-0.46 mb-5 break-words font-bold uppercase text-primary-slate" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.data?.header), 1),
        _createElementVNode("div", {
          class: "text-fs-14-lh-24-ls-0 font-normal text-primary-slate",
          innerHTML: _ctx.data?.content
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}