
import { defineComponent, PropType } from 'vue';

import StoriesLogo from '../../components/StoriesLogo/StoriesLogo.vue';
import TeaserCarousel from '../../components/TeaserCarousel/TeaserCarousel.vue';
import { Stories } from '../../types';

export default defineComponent({
  components: {
    StoriesLogo,
    TeaserCarousel,
  },
  props: {
    stories: Object as PropType<Stories>,
    default: null,
  },
});
