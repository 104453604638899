
import {
  defineComponent,
  PropType,
  onUnmounted,
  computed,
} from 'vue';
import { useStore } from 'vuex';

import Select from '@/components/Select/Select.vue';

export default defineComponent({
  components: {
    Select,
  },
  props: {
    category: {
      type: String,
    },
    keyName: {
      type: String,
    },
    data: {
      type: Object as PropType<{
        header: string | null;
        content: string | null;
        choices: Array<string> | null;
      }>,
    },
    formItem: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const dropdownError = computed(
      () => store.state.storiesModule.storyDropdownError
    );

    onUnmounted(() => {
      // reset dropdown option
      store.dispatch('storiesModule/unselectStoryDropdownOption');
      store.dispatch('storiesModule/unsetStoryDropdownError');
    });

    const onSelectOption = (option) => {
      store.dispatch('storiesModule/selectStoryDropdownOption', {
        key: props.keyName,
        option,
      });
      store.dispatch('storiesModule/setStoryDropdownError', {
        key: props.keyName,
        error: '',
      });
    };

    return {
      onSelectOption,
      dropdownError,
    };
  },
});
