import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "story-media" }
const _hoisted_2 = { class: "story-media-card px-8 pb-10 xl:pb-12" }
const _hoisted_3 = {
  class: "story-media-card__video mb-2 bg-primary-slate",
  controls: ""
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "story-media-card__headline text-fs-12-lh-20-ls-0.2 font-bold mb-1 xl:mb-2.5 text-primary-slate" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("video", _hoisted_3, [
        _createElementVNode("source", {
          src: _ctx.data?.video?.download_url,
          type: "video/mp4"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.data?.header), 1),
      _createElementVNode("div", {
        class: "story-media-card__text text-fs-14-lh-20-ls-0.2 font-normal text-primary-slate",
        innerHTML: _ctx.data?.content
      }, null, 8, _hoisted_6)
    ])
  ]))
}