
import { defineComponent, ComputedRef, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Stories } from '../../types';

import StoriesDetail from './StoriesDetail.vue';

const filterBySlug = (stories: Stories, slug: string) => ({
  items: stories?.items?.filter((story) => story?.meta?.slug === slug) || [],
  meta: stories.meta,
})

export default defineComponent({
  components: {
    StoriesDetail,
  },
  setup() {
    const { params } = useRoute()
    const store = useStore();

    store.dispatch('storiesModule/getStories');

    const stories: ComputedRef<Stories> = computed(() => {
      const currentStories = store.getters['storiesModule/allStories']
      return currentStories && params?.slug && filterBySlug(currentStories, params.slug as string)
    });

    return {
      stories,
    };
  },
});
