import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "story-cite bg-background-hard-white" }
const _hoisted_2 = { class: "story-cite-card py-10 px-8" }
const _hoisted_3 = { class: "story-cite-card__header text-fs-28-lh-38-ls-0.1 mb-1.5 font-normal break-words text-center" }
const _hoisted_4 = { class: "story-cite-card__author text-fs-12-lh-20-ls-0.2 font-normal text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data?.header), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.data?.author), 1)
    ])
  ]))
}