
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';

import Badge from '@/components/Badge/Badge.vue';
import { useStore } from 'vuex';

import {
  pushDataLayer,
  storyInteractionField,
} from '@/utils/GTMCustomDataLayers/stories';
import { StoryInteractionField } from '@/utils/GTMCustomDataLayers/types';

const CTA_LINK_STARTS_WITH_OPTIONS = ['https', 'http'];

export default defineComponent({
  components: {
    Badge,
    RouterLink,
  },
  props: {
    articlePageId: {
      type: Number,
      default: null,
    },
    articlePageSlug: {
      type: String,
      default: null,
    },
    articleHeader: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const prepareAndSendGTMObject = (title, text, url) => {
      const storyGTMObject: StoryInteractionField = {
        ...storyInteractionField,
        storyTitle: title,
        clickText: text,
        clickURL: url,
      };
      pushDataLayer('clickEvent', { storyInteractions: storyGTMObject });
    };
    const trackAction = () => {
      prepareAndSendGTMObject(
        props.articleHeader,
        props.data?.cta_title,
        CTA_LINK_STARTS_WITH_OPTIONS.some((item) =>
          props.data?.cta_link.includes(item)
        )
          ? props.data?.cta_link
          : `https://de.iqosclub.com/${props.data?.cta_link}?story=${props.articlePageSlug}`
      );
      store.dispatch('storiesModule/trackStory', props.articlePageId);
    };
    return {
      CTA_LINK_STARTS_WITH_OPTIONS,
      trackAction,
      prepareAndSendGTMObject,
    };
  },
});
